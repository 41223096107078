<template>
  <div class="dashboard">
    <b-container class="shlajsna">
      <b-row>
        <b-col cols="12">
          <h1 class="text-center">{{ $t('pages.add-page') }}</h1>
        </b-col>
        <b-col cols="12">
          <NavBar></NavBar>
        </b-col>
        <b-col cols="12" class="px-4">
          <PageForm />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import NavBar from "@/components/common/NavBar.vue"
import PageForm from '@/components/admin/page/PageForm.vue'
export default {
  name: 'AddPAge',
  components: { NavBar, PageForm },
  data: function() {
    return {
    }
  },
  created: function() {
  }

}
</script>
<style></style>
